
import React from 'react';
import RadioPlayer from '../components/RadioPlayer';
import '../components/AnimatedLogo/styles.css';
import { ReactComponent as AnimatedLogo } from '../assets/animated.svg'

const Home = () => {
  return (
    <div>
      <header className="App-header">
        Coming Soon!
        <div id="Home-Logo">
          <AnimatedLogo />
          {/* <RadioPlayer /> */}
        </div>
      </header>
    </div>
  );
};
  
export default Home;