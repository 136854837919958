
import React from 'react';
import SoundcloudEmbed from '../components/SoundcloudEmbed';  
import YoutubeEmbed from '../components/YoutubeEmbed';  

const Monthly = () => {
  return (
    <div>
        <header className="Monthly-header">
            <YoutubeEmbed embedId="7eVTd3y8q1c" />
            {/* <SoundcloudEmbed /> */}
        </header>
    </div>
  );
};
  
export default Monthly;