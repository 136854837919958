import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import footerLogo from '../src/assets/3.svg';

const AppFooter = () => (
    <div className='App-footer'>
        <Container>
            <Row className="justify-content-md-center mb-6 mt-5">
                <Col>
                    <img
                        alt='Happier Homes'
                        src={footerLogo}
                        width='50'
                    />{' '}
                </Col>
            </Row>
            <Row className="justify-content-md-center mb-6">
                <Col>
                    est 2023
                </Col>
            </Row>
        </Container>
    </div>
)

export default AppFooter;
