import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Ratio } from 'react-bootstrap';
import PropTypes from 'prop-types';

const YoutubeEmbed = ({ embedId }) => (
  <div className='mb-5'>
    <Container>
      <Ratio aspectRatio='16x9'>
          <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            allow='autoplay; encrypted-media;'
            allowFullScreen
            title='HH001-YT'
          />
      </Ratio>
    </Container>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;