
import React from 'react';
  
const Merchanside = () => {
  return (
    <div>
      <header className="App-header">
        <p className='Page-Text'>
          Maybe one day? 🤠
        </p>
      </header>
    </div>
  );
};
  
export default Merchanside;