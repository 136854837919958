import './App.css';
import React from 'react';
import HHNavbar from './components/NavBar';
import AppFooter from './AppFooter';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route } from 'react-router-dom';
import Home from './pages/home';
import Monthly from './pages/monthly';
import Merchandise from './pages/merchandise';
import About from './pages/about';

function App() {
  return (
    <div className="App font-face-gm">
      <Router>
        <HHNavbar />
        <Routes>
            <Route path='/' element={<Home/>} exact />
            <Route path='/monthly' element={<Monthly/>} exact />
            <Route path='/merch' element={<Merchandise/>} exact />
            <Route path='/about' element={<About/>} exact />
        </Routes>
      </Router>
      <AppFooter></AppFooter>
    </div>
  );
}

export default App;
