
import React from 'react';
  
// @TODO: Add an email form

const About = () => {
  return (
    <div>
      <header className="App-header">
        <p className='Page-Text'>
        Happier Homes is <strong>not</strong> a destination. 
        <br></br>
        It's right <strong>now</strong>, this place, in this moment. By yourself or surrounded by your loved ones. 
        <br></br>
        Enjoy the radio station (coming soon) or tune into the monthly drop.
        <br></br>
        Please reach out if you hear something, and it's yours, that you do not want to be broadcast through this channel. I'll gladly take down anything as I take no ownership in the music that is streamed through the radio show or through a mix. 
        <br></br>
        Contact: makinghappierhomes@gmail.com
        </p>
      </header>
    </div>
  );
};
  
export default About;