import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';  

const HHNavbar = () => {
  return (
    <Navbar className='HH-NavBar' variant='light'>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link className='HH-NavLink' href='/'>Radio</Nav.Link>
            <Nav.Link className='HH-NavLink' href='/monthly'>Monthly</Nav.Link>
            <Nav.Link className='HH-NavLink' href='/merch'>Merch</Nav.Link>
            <Nav.Link className='HH-NavLink' href='/about'>About</Nav.Link>
          </Nav>
          <div id='Nav-Logo'></div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
  
export default HHNavbar;